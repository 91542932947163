<template>
  <div id="app">
    <img class="banner" src="./assets/banner.jpg" />
    <div class="header">
      <div class="v_title">重庆科罗廖夫网络科技有限公司</div>
      <span class="v_select" :class="currIndex === 0 ? 'bg_yellow' : 'bg_aliceblue'" @click="onClick(0)">首页</span>
      <span style="color: #ffffff">|</span>
      <span class="v_select" :class="currIndex === 1 ? 'bg_yellow' : 'bg_aliceblue'" @click="onClick(1)">产品</span>
      <span style="color: #ffffff">|</span>
      <span class="v_select" :class="currIndex === 2 ? 'bg_yellow' : 'bg_aliceblue'" @click="onClick(2)">关于我们</span>
    </div>
    <div class="v_content" v-if="currIndex == 0">
      <div style="flex-direction: column; margin-left: 15%; margin-right: 15%">
        <div style="font-size: 35px">简介</div>
        <div style="font-size: 25px">Introduce</div>
        <p style="
            font-size: 15px;
            margin-top: 15px;
            line-height: 25px;
            text-indent: 30px;
          ">
          公司在商城优惠领域经过多年发展，现已在技术、产品、运营、商城领域积累丰富的经验，利用自己强大的产品开发能力和丰富的商品选购渠道，与商品平台合作，将优惠的商品呈现给目标用户，并取得不错的效果。并且公司拥有自己的产品开发团队和商品管理团队，未来将在商品选购、优惠场景、商品上新等多领域发展。同时公司在成语学习领域也有涉猎，目前已经有着丰富的精选成语库，让猜字学成语，后续也将会增加更多的趣味学习模式，探索更加高效的学习方式。也在省电清理方面有所涉猎，让用户合理使用手机，保障手机健康。
        </p>
      </div>
      <!-- <img
        style="margin-right: 25%; margin-left: 15%; width: 200px; height: 230px"
        src="./assets/bg02.jpg"
        alt=""
      /> -->
    </div>
    <!-- <div class="v_prodect" v-if="currIndex == 1">
      <div style="margin-left: 15%; margin-right: 15%">
        <div style="font-size: 35px">十月文学</div>
        <div style="font-size: 15px; margin-top: 15px; line-height: 25px">
          十月文学是一款超好用的小说阅读手机软件，聚集了众多海量小说任意阅读，支持一键搜索，轻松阅读全集，随时随地，畅快阅读！十月文学收录了小说类目，包含了都市言情、玄幻奇幻、武侠仙侠、官场权势、青春校园、穿越架空、悬疑、科幻灵异职场励志等海量原创小说，全都能够满足你！
        </div>
      </div>
      <div class="pro_img_view">
        <img src="./assets/bg05.png" alt="" />
        <img src="./assets/bg06.png" alt="" />
        <img src="./assets/bg07.png" alt="" />
        <img src="./assets/bg08.png" alt="" />
      </div>
    </div> -->
    <img v-if="currIndex == 1" src="./assets/sd-bg.jpg" style="height: 1080px; width: 100%" alt="" />
    <img v-if="currIndex == 1" src="./assets/mrfl-bg.png" style="height: 1080px; width: 100%" alt="" />
    <img v-if="currIndex == 1" src="./assets/czdr-bg.png" style="height: 1080px; width: 100%" alt="" />
    <div class="v_content_About" v-if="currIndex == 2">
      <div style="flex-direction: column">
        <div style="font-size: 35px; margin-top: 30px">关于我们</div>
        <div style="font-size: 25px">About us</div>
        <div class="v_text">
          公司在商城优惠领域经过多年发展，现已在技术、产品、运营、商城领域积累丰富的经验，利用自己强大的产品开发能力和丰富的商品选购渠道，与商品平台合作，将优惠的商品呈现给目标用户，并取得不错的效果。并且公司拥有自己的产品开发团队和商品管理团队，未来将在商品选购、优惠场景、商品上新等多领域发展。同时公司在成语学习领域也有涉猎，目前已经有着丰富的精选成语库，让猜字学成语，后续也将会增加更多的趣味学习模式，探索更加高效的学习方式。也在省电清理方面有所涉猎，让用户合理使用手机，保障手机健康。
        </div>
        <div class="v_text">
          1. 深耕商城优惠市场，拓展商城优惠场景
          公司多年来致力于移动互联网商城产品的开发与运营，在团队建设、选品渠道、技术开发，产品运营等方面积累了丰富的经验，在此基础上向商城优惠领域延伸，开发多款商城类产品，依托公司成熟的选品渠道和强大的技术产品实力，将继续在商城领域拓展更多业务。
        </div>
        <div class="v_text">
          2. 资深的技术、内容、产品、运营、选品团队
          公司在原有基础上，拓展app开发、前端开发、大数据分析等人员，不断打磨产品，并吸纳商城领域资深运营、产品，不断创新选品策略，拥有优秀的设计师团队和选品团队，有力的推进了公司的发展。
        </div>
        <div class="v_text">
          3. 互利共赢，协同发展
          公司在商城优惠上除自己设计选品外，与国内外各大商品平台和优质商家合作，对其优惠商品进行呈现，利用自己的渠道对其推广，达到合作共赢的结果，未来将还会合作更多优惠商品以及优惠活动，创造更多的优惠场景。
        </div>
        <div class="v_text">
          4. 优惠选品，场景无限想象
          公司通过自主开发设计和与其它商品平台公司合作，对优惠商品进行展示处理、美化处理，并利用移动技术的力量，以图片、比价、精选、视频等形式进行商品推广和优惠扩充，打造包含商品图片、商品视频、商品比价、商品精选、商品场景、优惠力度、活动推广等多方面的产业链。
        </div>
        <div class="v_text">
          5.精选题库，精选常用易错成语，打造丰富题库，由易入难，由简单到复杂，不断提升，渐进式学习。
        </div>
        <div class="v_text">
          6.激励奖励，每次通关给予一定激励，把握用户心态，累计答对还有连对奖励，乐享学习，不断学习，不断进步。
        </div>
        <div class="v_text">
          7.趣味学习，基础的猜字玩法，结合奖励机制，让用户喜欢学习，乐于学习，后续也将开发其他的趣味性学习玩法，丰富更多用户的学习场景。
        </div>
        <div class="v_text">
          8.省电清理，让用户合理使用手机，保障手机健康。
        </div>
      </div>
    </div>
    <div class="footr">
      <div>
        <p style="font-size: 10px">
          CopyRight © 2022 - 2023 重庆科罗廖夫网络科技有限公司
          版权所有备案编号：<a href="https://beian.miit.gov.cn/" target="_blank">渝ICP备2023000105号-1</a>
        </p>

        <p style="font-size: 10px">
          公司地址：重庆市渝北区回兴街道宝圣大道215号西政国际学术交流中心3幢2-商务（自编号
          007）
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currIndex: 0,
    };
  },
  methods: {
    onClick(i) {
      this.currIndex = i;
    },
  },
};
</script>

<style >
#app {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.banner {
  width: 100%;
  height: 580px;
}

.header {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 15%;
  width: 100%;
}

.v_title {
  font-size: 30px;
  color: aliceblue;
  font-weight: 600;
  margin-right: 100px;
}

.v_select {
  cursor: pointer;
  margin: 0% 5%;
  font-size: 15px;
  font-weight: 700;
}

.bg_yellow {
  color: yellow;
}

.bg_aliceblue {
  color: aliceblue;
}

.v_content {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
}

.swper_view {
  display: flex;
  flex-direction: row;
}

/* .v_prodect {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.pro_img_view {
  display: flex;
  justify-content: space-around;
  margin-left: 15%;
  margin-right: 15%;
  height: 450px;
  margin-top: 30px;
  margin-bottom: 30px;
} */
.v_content_About {
  flex-direction: column;
  margin-left: 15%;
  margin-right: 15%;
  margin-bottom: 10px;
}

.footr {
  display: flex;
  justify-content: center;
  align-items: center;
  background: darkgray;
  height: 100px;
}

.index_view {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.v_text {
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
